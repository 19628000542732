import { createContext, useEffect, useState } from "react";
import axios from 'axios'
import $api from "../http/index.ts";
import { BACKURL } from "../conf.js"
import { Loading } from "../Components/Loading/Loading";

export const QtdContext = createContext();

const QtdProvider = (props) => {
  const [produtosSelecionados, setProdutosSelecionados] = useState([]);
  const [produtosSelecionadosDonations, setProdutosSelecionadosDonations] = useState([]);
  const [quantidadeModalBtnDonations, setQuantidadeModalBtnDonations] = useState(1);

  const [quantidadeModalBtn, setQuantidadeModalBtn] = useState(1);
  const [serverProd, setServerProd] = useState([]);
  const [scladDot, setScladDot] = useState([]);
  const [dots, setDots] = useState(['Советский']);
  const [spec, setSpec] = useState([]);
  const [modalCart, setModalCart] = useState(false);
  const [errorOrder, serErrorOrder] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [menu, setMenu] = useState(false);
  const [tabs, setTabs] = useState(false);
  const [tabs2, setTabs2] = useState(false);

  const [servOrders, setServOrders] = useState([]);
  const [optUsers, setOptUsers] = useState([]);
  const [priuts, setPriuts] = useState([{
    id: 1,
    name: 'Приют для животных "Преданная душа"',
    shortname: '"Преданная душа"',
    tel: '+79217115595',
    adress: 'хутор',
    descriptions: [{
      titel: 'Абзац 1',
      about: ['подабзац 1', 'подабзац 2'],
      photo: 'Абзац 1',
      side: 'left',


    }],
    photos: []
  },
  {
    id: 2,
    name: 'Приют 2',
    shortname: 'Приют 2',
    tel: '89078989988',
    adress: 'Советский тест 204',
    descriptions: [{
      titel: 'Абзац 1',
      about: ['подабзац 1', 'подабзац 2'],

    }],
    photos: []
  }]);
  const [donatePriutModal, setDonatePriutModal] = useState(false);
  //OPT
  const [opt, setOpt] = useState({ auth: false, user: {} });
  const [users, setUsers] = useState([]);

  //ADMIN
  // const [auth, setAuth] = useState(false);
  const [admin, setAdmin] = useState({ auth: false, user: '' });
  const [admins, setAdmins] = useState([]);
  const [sclad, setSclad] = useState([]);
  const [PromoCodes, setPromoCodes] = useState([]);
  const [modalPromo, setModalPromo] = useState(false);
  const [clicks, setClicks] = useState([]);
  const [scladHistory, setScladHistory] = useState([]);
  const [QR, setQR] = useState([]);
  const [modalQR, setModalQR] = useState(false);


  const [QRcode, setQRcode] = useState('');
  const [modalQRcode, setModalQRcode] = useState(false);


  const [stat, setStat] = useState({})
  const [modalEdit, setModalEdit] = useState(false);
  const [modalEditOptUser, setModalEditOptUser] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [modalInfoSpec, setModalInfoSpec] = useState(false);
  const [modalEditSpec, setModalEditSpec] = useState(false);
  const [editprod_id, seteditprod_id] = useState(null);
  const [editprod_suh, seteditprod_suh] = useState(false);
  const [modalAddSpec, setModalAddSpec] = useState(false);
  const [modalInfoScladHistory, setModalInfoScladHistory] = useState(false);

  const [modalEditProduct, setModalEditProduct] = useState(false);
  const [modalAddProduct, setModalAddProduct] = useState(false);
  const [infomodal, setInfoModal] = useState({})
  const [factory, setfactory] = useState([])
  const [productGroups, setproductGroups] = useState([])
  const [productDots, setproductDots] = useState([])
  const [shops, setShops] = useState([])

  const checkAuth = async () => {
    try {
      const response = await axios.get(`${BACKURL}/api/utbteam/refresh`,
        { withCredentials: true }
      )
      // console.log('checkAuth()', response);
      // console.log('===', response)
      localStorage.setItem('token', response.data.accessToken);
      setAdmin({ auth: true, user: response.data.user.login, role: response.data.user.role });
      return
    } catch (e) {
      // console.log(e.response?.data?.message);
      setAdmin({ auth: false, user: '' });

    }
  }
  const checkOpt = async () => {
    try {
      const response = await axios.get(`${BACKURL}/api/optuser/refresh`,
        { withCredentials: true }
      )
      // console.log('checkAuth()', response);
      // console.log('===', response)
      localStorage.setItem('token', response.data.accessToken);
      setOpt({ auth: true, user: response.data.user });
      // set?Admin(response.data.user);
    } catch (e) {
      // console.log(e.response?.data?.message);
      setOpt({ auth: false, user: '' })   // setAdmin('')
    }
  }
  // const [Start, setStart]=useState(false)
  const [loading, setLoading] = useState(true);

  const test1 = async () => {
    setLoading(true)
    if (localStorage.getItem('token')) {
      // console.log('have access token!')
      await checkAuth()

      // await checkOpt()

    }

    let prod = await axios.get(`${BACKURL}/api/getpruducts`)
    let prodGropus = await axios.get(`${BACKURL}/api/getpruductgrops`)
    let prodDots = await axios.get(`${BACKURL}/api/productsdot/getall`)
    let shopsData = await axios.get(`${BACKURL}/api/shops/getall`)
    let prutsdData = await axios.get(`${BACKURL}/api/priuts/getall`)

    // console.log(shopsData.data)
    let prelist = []
    let prelistDry = []
    // var limit = 24 * 3600 * 1000; // 24 часа
    var limit = 21000; // 24 часа
    var localStorageInitTime = localStorage.getItem('localStorageInitTime');
    if (localStorageInitTime === null) {

      localStorage.setItem('localStorageInitTime', +new Date());
    } else if (+new Date() - localStorageInitTime > limit) {
      // console.log('i am here!')
      localStorage.setItem("pet", [])
      localStorage.removeItem("petNew");
      localStorage.removeItem("produtosSelecionados")
      localStorage.removeItem("quantidadeModalBtn")

      localStorage.removeItem("petNewDonations");
      localStorage.removeItem("produtosSelecionadosDonations")
      localStorage.removeItem("quantidadeModalBtnDonations")
      localStorage.setItem('localStorageInitTime', +new Date())
    }
    let history = JSON.parse(localStorage.getItem("petNew"));
    let produtosSelecionadosS = JSON.parse(localStorage.getItem("produtosSelecionados"));
    let quantidadeModalBtnS = JSON.parse(localStorage.getItem("quantidadeModalBtn"));
    // console.log(history, produtosSelecionadosS, quantidadeModalBtnS)
    let produtosSelecionadosSDonations = JSON.parse(localStorage.getItem("produtosSelecionadosDonations"));
    let quantidadeModalBtnSDonations = JSON.parse(localStorage.getItem("quantidadeModalBtnDonations"));

    for (let i = 0; i < prod.data.length; i++) {
      // console.log(prod.data[i].kategory)
      // console.log(prod.data[i])shcode
      prelist[i] = ({
        donSale: prod.data[i].donSale,
        donation: prod.data[i].donation,
        type: prod.data[i].type,
        showed: prod.data[i].showed,
        brand: prod.data[i].brand,
        shcode: prod.data[i].shcode,
        kategory: prod.data[i].kategory,
        name: prod.data[i].name,
        brakqty: prod.data[i].brakqty,
        mnt: prod.data[i].mnt,
        podkategory: prod.data[i].podkategory,
        price: Number(prod.data[i].price),
        optprice: Number(prod.data[i].optprice),
        id: Number(prod.data[i].id),
        forwho: prod.data[i].forwho,
        donations: [],
        quantidade: (prod.data[i].kategory == 'сушенная продукция для собак' && prod.data[i].mnt == 'кг') ? 0.3 : 1,
        vipprice: prod.data[i].vipprice.map(pf => JSON.parse(pf)),
        // planedqty:prod.data[i].planedqty,
        photo: prod.data[i].photo,
        text: prod.data[i].about,
        recomendation: prod.data[i].recomendation,
        scladqty: prod.data[i].scladqty,
      })
      // console.log(prelist[i])
    }
    if (!history) {
      prelist = history
    }
    if (produtosSelecionadosS != null) {
      setProdutosSelecionados(produtosSelecionadosS)
    }
    if (!quantidadeModalBtnS != null) {
      setQuantidadeModalBtn(quantidadeModalBtnS)
    }
    // console.log()
    if (produtosSelecionadosSDonations != null) {
      setProdutosSelecionadosDonations(produtosSelecionadosSDonations)
    }
    if (!quantidadeModalBtnSDonations != null) {
      setQuantidadeModalBtnDonations(quantidadeModalBtnSDonations)
    }
    setproductGroups(prodGropus.data)
    // console.log(prodGropus.data)
    setSclad(prelist)
    setServerProd(prelist);
    setShops(shopsData.data);
    setproductDots(prodDots.data);
    setPriuts(prutsdData.data)
    setLoading(false)
    // await test()
    return 'ok'
  }

  useEffect(() => {
    setLoading(true)

    test1()
    // while(!Start){
    //   // console.log('loading...')
    // }
    //  Promise.all([test1()]).then((t)=>console.log(t))
  }, [])
  useEffect(() => {
    localStorage.setItem('localStorageInitTime', +new Date());

    localStorage.setItem("pet", JSON.stringify(produtosSelecionados))
    localStorage.setItem("petNew", JSON.stringify(produtosSelecionados));
    // localStorage.setItem("produtosSelecionadosDonations", JSON.stringify(produtosSelecionadosDonations));

  }, [produtosSelecionados]);

  return (<>
    {loading ? <Loading /> :
      <QtdContext.Provider value={{ editprod_suh, seteditprod_suh, quantidadeModalBtnDonations, setQuantidadeModalBtnDonations, produtosSelecionadosDonations, setProdutosSelecionadosDonations, donatePriutModal, setDonatePriutModal, priuts, setPriuts, shops, setShops, productDots, setproductDots, tabs2, setTabs2, editprod_id, seteditprod_id, productGroups, setproductGroups, tabs, setTabs, menu, setMenu, showDatePicker, setShowDatePicker, users, setUsers, dots, scladDot, setScladDot, admins, setAdmins, modalInfoScladHistory, setModalInfoScladHistory, scladHistory, setScladHistory, factory, setfactory, modalAddSpec, setModalAddSpec, modalEditSpec, setModalEditSpec, modalInfoSpec, setModalInfoSpec, spec, setSpec, QRcode, setQRcode, modalQRcode, setModalQRcode, QR, setQR, modalQR, setModalQR, clicks, setClicks, modalPromo, setModalPromo, PromoCodes, setPromoCodes, setAdmin, admin, modalEditOptUser, setModalEditOptUser, optUsers, setOptUsers, opt, setOpt, modalAddProduct, setModalAddProduct, modalEditProduct, setModalEditProduct, infomodal, setInfoModal, modalInfo, setModalInfo, stat, setStat, setModalEdit, servOrders, setServOrders, modalEdit, setModalEdit, sclad, setSclad, errorOrder, serErrorOrder, modalCart, setModalCart, setServerProd, serverProd, produtosSelecionados, setProdutosSelecionados, quantidadeModalBtn, setQuantidadeModalBtn }}>
        {props.children}
      </QtdContext.Provider>
    }
  </>
  );
};

export default QtdProvider;
