import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Sclad.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../../context/qtdContext";
import $api from "../../../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalEditProduct from '../../../Modal/ModalEditProduct';
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../../NewOrders/react-confirm-alert.css'; // Import css
import { FcInfo } from "react-icons/fc";
import { BACKURL } from '../../../../../../../conf.js'
import styled from "styled-components";
import Loading from 'react-fullscreen-loading';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const Catalog = () => {
    const { shops, editprod_suh, seteditprod_suh, productDots, setproductDots, optUsers, admin, sclad, setSclad, seteditprod_id, setModalEditProduct } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);
    const [loading, setLoading] = useState(false);

    const catalog = sclad.filter(a => a.type == 'Каталог товаров');

    //kategory Каталог товаров
    ////для кошек || Для кошек и собак forwho
    const cats = sclad.filter(a => (a.forwho == 'Для кошек' || a.forwho == 'Для кошек и собак'));
    //Наполнитель podkategory
    const vc = cats.filter(a => a.kategory == 'Уход');
    // console.log('cats', cats)
    // console.log('vc', vc)

    //ВетАптека
    const apteka = cats.filter(a => a.kategory == 'ВетАптека');
    // console.log('apteka', apteka)

    //pauchi
    const puch = cats.filter(a => a.kategory == 'Паучи');
    // console.log('toys', toys)

    const toys = cats.filter(a => a.kategory == 'Аксессуары');
    // console.log('toys', toys)

    //сухой корм
    const suh = cats.filter(a => a.kategory == 'сухой корм');
    // console.log('suh', suh)




    ////для собак || Для кошек и собак
    const dogs = sclad.filter(a => (a.forwho == 'Для собак' || a.forwho == 'Для кошек и собак'));

    const vcdogs = dogs.filter(a => a.kategory == 'Уход');

    //ВетАптека
    //pauchi
    const puchdogs = dogs.filter(a => a.kategory == 'Паучи');
    // console.log('toys', toys)

    const aptekadogs = dogs.filter(a => a.kategory == 'ВетАптека');

    //Игрушки
    const toysdogs = dogs.filter(a => a.kategory == 'Аксессуары');

    //сухой корм
    const suhdogs = dogs.filter(a => a.kategory == 'сухой корм');
    //  console.log('suh', suh)

    const TCheckBox = styled.input.attrs({
        type: "checkbox"
    })`
        display: none;
      `;

    const TCheckBoxWrapper = styled.div`
        background: ${p => (p.checked ? "#3f81ff" : "#ececec")};
        border-radius: 4rem;
        height: 32px;
        width: 68px;
        position: relative;
        transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
        cursor: pointer;
      `;

    const TCheckBoxToggle = styled.div`
        background: #fff;
        border-radius: 4rem;
        height: 26px;
        width: 26px;
        top: 3px;
        left: ${p => (p.checked ? "38px" : "4px")};
        position: absolute;
        transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
      `;

    const ToggleCheckbox = props => {
        return (
            <TCheckBoxWrapper checked={props.checked} onClick={props.onChange}>
                <TCheckBoxToggle checked={props.checked} />
                <TCheckBox {...props} />
            </TCheckBoxWrapper>
        );
    };
    const toggle = async (id) => {
        try {
            setLoading(true)
            // console.log(id)
            const deleteProd = await $api.get(`${BACKURL}/api/utbteam/products/showed/${id}`)
            setSclad(sclad.map(pr => {
                if (pr.id == id) {
                    return deleteProd.data
                } else {
                    return pr
                }
            }))
            //   setSclad(sclad.map(item => item.id == id ? deleteProd.data : item))
            setLoading(false)
        } catch (e) {
            setLoading(false)

            console.log(e)
        }
    };
    // const [thisChange, setThisChange] = useState({})

    const edit = (id) => {
        seteditprod_id(id)
        seteditprod_suh(false)
        setModalEditProduct(true)

    }
    const deleteProd = async (id) => {
        const DeletedId = await $api.get(`${BACKURL}/api/utbteam/deleteproduct/${id}`)
        const newprod = sclad.filter((post) => post.id != id)
        setSclad(newprod)
        alert('Товар удален! Эта функция будет ограничена!')
    }
    const submit = async (func, data) => {
        confirmAlert({
            title: 'Подтвердите, чтобы продолжить!',
            message: 'Вы уверены, в том, что хотите удалить ордер?',
            buttons: [
                {
                    label: 'Да!',
                    onClick: async () => await func(data)
                },
                {
                    label: 'Нет!',
                    //   onClick: () => alert('Click No')
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };

    const render = (prods) => {
        return (
            <div style={{ overflowX: 'auto', width: '100%' }}>

                <br />
                <table className={style.table}>
                    <thead className={style.tableth}>

                        <tr>
                            <td className={style.tabletd}>Код</td>
                            <td className={style.tabletd}>Наименоване</td>
                            <td className={style.tabletd}>Категория</td>
                            <td className={style.tabletd}>Подкатегория</td>
                            <td className={style.tabletd}>Для кого</td>

                            {shops.length > 0 && (shops.map(pr => <td className={style.tabletd}>{pr.name}</td>))}

                            <td className={style.tabletd}>Розница</td>
                            <td className={style.tabletd}>Показывается</td>
                            <td className={style.tabletd}>Действия</td>

                        </tr>
                    </thead>
                    <tbody>
                        {prods.map(
                            (prod, index) => <>
                                <tr>
                                    <td className={style.tabletd}>

                                        <div className={style.cell}>

                                            {prod.shcode}
                                        </div>


                                    </td>

                                    <td className={style.tabletd}>

                                        {/* <div className={style.cell}> */}
                                        {prod.name}
                                        {/* </div> */}


                                    </td>
                                    <td className={style.tabletd}>  {prod.kategory}</td>
                                    <td className={style.tabletd}>  {prod.podkategory}</td>
                                    <td className={style.tabletd}>  {prod.forwho}</td>

                                    {shops.length > 0 && (shops.map(pr => {

                                        const scladqty = productDots.find(pd => pd.prod_sh == prod.shcode && pd.prod_id == prod.id && pr.id == pd.shop_id)
                                        // console.log(productDots, scladqty)
                                        return <td className={style.tabletd}>

                                            <b>   {scladqty?scladqty.scladqty:0}{prod.mnt}</b>



                                        </td>
                                    }))}




                                    <td className={style.tabletd}>
                                        <div className={style.cell}>

                                            {prod.price}RUB
                                        </div>


                                    </td>
                                    <td className={style.tabletd}>
                                        <div className={style.cell}>
                                            <ToggleCheckbox checked={prod.showed} onChange={() => toggle(prod.id)} />

                                        </div>


                                    </td>



                                    <td className={style.tabletd}>
                                        <div className={style.cell}>
                                            <AiFillDelete onClick={() => { submit(deleteProd, prod.id) }} className={style.icon} />
                                            <FaEdit className={style.icon} onClick={() => edit(prod.id)} />

                                        </div>


                                    </td>


                                </tr>

                            </>
                        )}
                    </tbody>
                </table>

            </div>
        )
    }

    return (<>
        <Tabs >
            <TabList style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexWrap: 'wrap' }}>
                <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Для кошек</Tab>
                <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Для собак</Tab>
            </TabList>
            <TabPanel>
                <Tabs forceRenderTabPanel>
                    <TabList style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexWrap: 'wrap' }}>
                        <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Уход</Tab>
                        <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Паучи</Tab>
                        <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>ВетАптека</Tab>
                        <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Аксессуары</Tab>
                        <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Сухой корм</Tab>


                    </TabList>
                    <TabPanel>{render(vc)}</TabPanel>
                    <TabPanel>{render(puch)}</TabPanel>
                    <TabPanel>{render(apteka)}</TabPanel>
                    <TabPanel>{render(toys)}</TabPanel>
                    <TabPanel>{render(suh)}</TabPanel>
                    {/* <TabPanel>{render(shampoo)}</TabPanel> */}
                </Tabs>

            </TabPanel>
            <TabPanel>

                <Tabs forceRenderTabPanel>
                    <TabList style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', flexWrap: 'wrap' }}>
                        <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Уход</Tab>
                        <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Паучи</Tab>
                        <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>ВетАптека</Tab>
                        <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Аксессуары</Tab>
                        <Tab style={{ display: 'flex', width: 'auto', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>Сухой корм</Tab>

                    </TabList>

                    <TabPanel>{render(vcdogs)}</TabPanel>
                    <TabPanel>{render(puchdogs)}</TabPanel>
                    <TabPanel>{render(aptekadogs)}</TabPanel>
                    <TabPanel>{render(toysdogs)}</TabPanel>

                    <TabPanel>{render(suhdogs)}</TabPanel>
                </Tabs>
            </TabPanel>
        </Tabs>
        {/* {modalEditProduct && (
            <ModalEditProduct
                id={thisChange}
            // setThisChange={setThisChange}
            // prod = {prod}
            />

        )} */}
        {loading && (
            <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
        )}
    </>
    )
}
export default Catalog